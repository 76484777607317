<template>
  <div>
    <a
      :href="
        'serve.php?f=administration&f2=explorers&function=download&projectName=' +
        result.valueLine.projectName +
        '&tags=' +
        result.valueLine.tags.join(',') +
        '&hostnamePrefix=' +
        result.valueLine.hostnamePrefix +
        '&create=' +
        result.valueLine.create
      "
    >
      <primaryButton
        class="mr-2"
        :attributesArray="functionElement['attributesArray']"
        type="button"
        :value="functionElement['functionName']"
        v-on="$listeners"
        :label="functionElement['functionName']"
        color="save"
        :disabled="!isValid || loading"
        :loading="loading && isValid"
        icon="mdi-move-resize-variant"
      ></primaryButton>
    </a>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";

export default {
  props: ["isValid", "loading", "functionElement", "result"],
  components: {
    primaryButton,
  },
};
</script>